const HelpArea = (props: any) => {
    return (
        <div className={`center help-area ${props.className}`}>
            <h3 className="mb-0 mt-1">DISPLAY</h3>
            The goal of the game is to collect all of the Dragon Mothers and clear the Board. <br />
            The top of the screen is the Board. <br />
            The currently selected Dragon is displayed below the Board with a pulsing orange outline. <br />
            The area with the currently selected Dragon is your Hand. <br />
            At the start of the game, your Hand has 1 Dragon. <br />
            As the game progresses, you will eventually have more Dragons in your Hand (Max 12). <br />
            When a Dragon in your Hand will lay an Egg, there is a preview of that Egg at the bottom of the Dragon. <br />
            <h3 className="mb-0 mt-1">GAMEPLAY</h3>
            Click on any empty cell on the Board to place the selected Dragon. <br />
            When 3 or more tiles (Dragons, Eggs and/or a Dragon Mother) are grouped together of the same color, a match is resolved. <br />
            All Dragons in the match will be replace with their Egg, if applicable. <br />
            Dragons that don't lay an Egg are removed the first time they're matched. <br />
            All Eggs and Dragon Mothers will be removed from the Board when matched. <br />
            Making a match of more than 4 will INCREASE your Hand size. <br />
            Making a combo match (made when a Dragon lays an Egg and automatically makes a new match) will INCREASE your Hand size. <br />
            Matching with a Dragon Mother will DECREASE your Hand size. <br />
            When you have multiple Dragons in your Hand, you can click any Dragon in your Hand to make it the selected Dragon. <br />
            New Dragons are drawn automatically. If all 66 Dragons have been drawn, you will be able to continue to play, and you will see empty slots show up in your Hand. <br />
            <h3 className="mb-0 mt-1">END</h3>
            If you play all of the Dragons without clearing the Board, you lose. <br />
            If the Board is ever full, you lose. <br />
            If your Hand size is decreased to 0, you lose. <br />
            If you are able to clear the Board, you win. <br />
            If you win, you will be given a score: <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 point for each Dragon that was not drawn <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1 point for every Dragon in your hand <br />
            <br />
            <div className="center text-center">
                <button className="btn btn-success" onClick={props.onClick}>Dismiss</button>
            </div>
        </div>
    );
};

export default HelpArea;