import React from "react";

const HandCell = (props: any) => {
    let imgSrc = 'assets/Bonus 0.png';
    let imgSrc2 = '';
    let imgOpacity = 0.5;
    if (props.tile) {
        imgSrc = `assets/${props.tile.Front} Dragon.png`;
        if (props.tile.Front !== props.tile.Back) {
            imgSrc2 = `assets/${props.tile.Back} Egg.png`;
        }
        imgOpacity = 1;
    }

    let divClass = "handCell";
    if (props.selected === props.index) {
        divClass += " selected";
    }
    if (!props.tile) {
        divClass = "handCell";
    }

    return (
        <div style={{display: "inline-block", paddingLeft: 2, paddingRight: 2}}>
            <div className={divClass}>
                <div className="highlight"></div>
                <img src={imgSrc} style={{opacity: imgOpacity, position: "relative"}} onClick={() => props.onClick(props.index)} alt='' />
                {(imgSrc2 !== '') && <img src={imgSrc2} className="topImage" style={{opacity: 0.9, position: "relative"}} onClick={() => props.onClick(props.index)} alt='' />}
            </div>
        </div>
    );
};

export default HandCell;