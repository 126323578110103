const SurviveHelpArea = (props: any) => {
    return (
        <div className={`center help-area ${props.className}`}>
            <h3 className="mb-0 mt-1">DISPLAY</h3>
            The goal of the game is to send as many Dragons to the Board as possible without collecting the Eggs in the corners of the Board. <br />
            The top of the screen is the Board. <br />
            The next Dragon to place is below the center of the board. <br />
            The number under the next Dragon is how many Dragons are still waiting to be placed. <br />
            <h3 className="mb-0 mt-1">GAMEPLAY</h3>
            Click on any empty cell to place the Dragon. <br />
            When 3 or more tiles (Dragons, Eggs and/or a Dragon Mother) are grouped together of the same color, a match is resolved. <br />
            All Eggs in the match will be removed from the board. <br />
            Most Dragons in the match will flip over to reveal the Egg color. <br />
            Some Dragons won't lay an Egg. They will be removed automatically. <br />
            <br />
            <h3 className="mb-0 mt-1">END</h3>
            If the Board is full, the game ends. <br />
            If an Egg is removed from any of the 6 corners of the Board, the game ends. <br />
            If all 66 tiles are successfully played to the Board, the game ends. <br />
            When the game ends, you will be given a score: <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 points for every Egg that has been removed from the Board <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 points for every Dragon still in a corner of the Board <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 point for every Egg on the Board <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-10 points for every empty corner <br />
            <br />
            <div className="center text-center">
                <button className="btn btn-success" onClick={props.onClick}>Dismiss</button>
            </div>
        </div>
    );
};

export default SurviveHelpArea;