import React from "react";

const HexCellMother = (props: any) => {
    let imgSrc = "assets/Blue.png";
    let imgOpacity = 0;
    let full = false;
    if (props.tile && props.tile.Front !== '') {
        full = true;
        imgSrc = `assets/${props.tile.Back} Mother.png`;
        imgOpacity = 1;
    }
    if (props.click == undefined) full = true;
    return (
        <div className={"hexCell board_col_" + props.col + (
            props.first ? 
                " first" : 
                props.last ? 
                    " last" : 
                    props.active ? 
                        props.visible ? 
                            " active" : 
                            " active hidden" : 
                        "")}>
            <div className="hex">
                <img src={imgSrc} style={{opacity: imgOpacity}} onClick={full ? () => {} : () => props.click(props.index)} alt='' />
            </div>
        </div>
    );
};

export default HexCellMother;