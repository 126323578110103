import React from "react";
import DamageAlert from "../components/Damage/DamageAlert";
import HandArea from "../components/Hand/HandArea";
import HelpArea from "../components/Help/HelpArea";
import HexCell from "../components/Hexes/HexCell";
import { Controller, TileModel } from "../features/Controller";
import HexCellMother from "../components/Hexes/HexCellMother";
import { QuestionCircle } from "react-bootstrap-icons";

interface GameState {
    Tiles: TileModel[],
    TilesDrawn: number,
    Score: number,
    GameOver: boolean,
    WindowWidth: number,
    WindowHeight: number,
    ViewingHelp: boolean
}

const NullTile: TileModel = {
    Front: '',
    Back: '',
    Flipped: false
}

class Game extends React.Component<any, GameState, any> {
    clickedIdx: number = -1;
    isScoring: boolean = false;
    handSize: number = 1;
    handTiles: TileModel[] = [];
    selectedCell: number = 0;
    constructor(props: any) {
        super(props);
        if (!this.state) {
            this.state = {
                Tiles: [Controller.Tiles[0],NullTile,NullTile,Controller.Tiles[1],NullTile,Controller.Tiles[2],NullTile,NullTile,NullTile,NullTile,NullTile,NullTile,NullTile,Controller.Tiles[3],NullTile,Controller.Tiles[4],NullTile,NullTile,Controller.Tiles[5],Controller.Tiles[6],Controller.Tiles[7],Controller.Tiles[8],Controller.Tiles[9],Controller.Tiles[10],Controller.Tiles[11]],
                TilesDrawn: 13,
                Score: 0,
                GameOver: false,
                WindowWidth: window.innerWidth,
                WindowHeight: window.innerHeight,
                ViewingHelp: true
            }
        }
        this.handTiles.push(Controller.Tiles[12]);
        document.title = 'Honey Clear';
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize.bind(this))
    }

    render() {
        return (
            <>
            <div className="board">
            <div className="board-row board-row_1">
                <HexCellMother tile={this.state.Tiles[19]} index={18} col={4} />
            </div>
            <div className="board-row board-row_2">
                <HexCell />
            </div>
            <div className="board-row board-row_3">
                <HexCell tile={this.state.Tiles[0]} index={0} col={4} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row board-row_4">
                <HexCellMother tile={this.state.Tiles[20]} index={13} col={1} first />
                <HexCell tile={this.state.Tiles[1]} index={1} col={3} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[2]} index={2} col={5} click={this.onClick.bind(this)} />
                <HexCellMother tile={this.state.Tiles[21]} index={15} col={7} last />
            </div>
            <div className="board-row board-row_5">
                <HexCell tile={this.state.Tiles[3]} index={3} col={2} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[4]} index={4} col={4} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[5]} index={5} col={6} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row board-row_6">
                <HexCell tile={this.state.Tiles[6]} index={6} col={3} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[7]} index={7} col={5} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row board-row_7">
                <HexCell tile={this.state.Tiles[8]} index={8}   col={2} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[9]} index={9}   col={4} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[10]} index={10} col={6} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row board-row_8">
                <HexCell tile={this.state.Tiles[11]} index={11} col={3} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[12]} index={12} col={5} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row board-row_9">
                <HexCell tile={this.state.Tiles[13]} index={13} col={2} click={this.onClick.bind(this)} first />
                <HexCell tile={this.state.Tiles[14]} index={14} col={4} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[15]} index={15} col={6} click={this.onClick.bind(this)} last />
            </div>
            <div className="board-row board-row_10">
                <HexCellMother tile={this.state.Tiles[22]} index={3} col={1} first />
                <HexCell tile={this.state.Tiles[16]} index={16} col={3} click={this.onClick.bind(this)} />
                <HexCell tile={this.state.Tiles[17]} index={17} col={5} click={this.onClick.bind(this)} />
                <HexCellMother tile={this.state.Tiles[23]} index={5} col={7} last />
            </div>
            <div className="board-row board-row_11">
                <HexCell tile={this.state.Tiles[18]} index={18} col={4} click={this.onClick.bind(this)} />
            </div>
            <div className="board-row board-row_12">
                <HexCell />
            </div>
            <div className="board-row board-row_13">
                <HexCellMother tile={this.state.Tiles[24]} index={0} col={4} />
            </div>
            </div>
            <div>
                <HandArea tiles={this.handTiles} handSize={this.handSize} selected={this.selectedCell} onClick={this.onClickHand.bind(this)} />
                <div style={{width: 121, textAlign: "center", position: "absolute", left: "calc(50% - 425px)", top: 700}}>
                Draws Remaining:<h1>{this.tilesInBag()}</h1>
                </div>
            </div>
            <h1 className="help-icon">
                <QuestionCircle onClick={() => this.setState({ViewingHelp: true})} />
            </h1>
            <DamageAlert score={this.state.Score} gameOver={this.state.GameOver} width={800} height={this.state.WindowHeight} />
            <HelpArea className={this.state.ViewingHelp ? '' : 'hidden'} onClick={() => this.setState({ViewingHelp: false})} />
            </>
        );
    }

    onClick(idx: number) {
        if (!this.isScoring && this.handTiles.length > 0) {
            let selection = this.selectedCell;
            if (selection === -1) {
                selection = this.handTiles.length - 1;
            }
            this.isScoring = true;
            let tiles = this.state.Tiles;
            tiles[idx] = this.handTiles[selection];
            Controller.AllCells[idx].CurrentTile = Controller.Tiles.indexOf(this.handTiles[selection]);
            Controller.AllCells[idx].Color = tiles[idx].Front;
            this.handTiles.splice(selection, 1);
            this.clickedIdx = idx;
            this.setState({Tiles: tiles});
            if (this.selectedCell >= this.handTiles.length) this.selectedCell--;
            setTimeout(this.scoreMatch.bind(this), 500);
        }
    }

    onClickHand(idx: number) {
        if (this.handTiles.length > idx) this.selectedCell = idx;
        this.setState({GameOver: false});
    }

    drawTile() {
        if (this.handTiles.length < this.handSize && this.tilesInBag() > 0) {
            this.handTiles.push(Controller.Tiles[this.state.TilesDrawn]);
            this.setState({TilesDrawn: this.state.TilesDrawn + 1});
            if (this.selectedCell < 0 || this.selectedCell >= this.handTiles.length) {
                this.selectedCell = 0;
            }
        }
    }

    scoreMatch() {
        let scored = Controller.ScoreMatch(this.clickedIdx);
        if (Controller.ShrinkReserve > 0) {
            this.handSize -= Controller.ShrinkReserve;
            Controller.ShrinkReserve = 0;
        }

        let tiles = this.setEmptyCells();

        if (scored) {
            Controller.CurrentlyActive = [...Controller.ToBeActive];
            Controller.ToBeActive = [];
            if (Controller.LastMatchLength > 4 && this.handSize < 12) {
                Controller.ExtraReserve = true;
            }
            setTimeout(this.scoreAgain.bind(this), 500);
        } else {
            if (this.checkForWin()) {
                this.setState({Score: this.scoreGame(true), Tiles: tiles, GameOver: true});
                return;
            }
            if (this.checkForLose()) {
                this.setState({Score: this.scoreGame(false), Tiles: tiles, GameOver: true});
                return;
            }
            else {
                this.isScoring = false;
            }
            this.drawTile();
        }
        this.setState({Tiles: tiles});
    }

    scoreAgain() {
        let scored = false;
        while(!scored && Controller.CurrentlyActive.length > 0) {
            scored = Controller.ScoreMatch(Controller.CurrentlyActive[0]);
            if (Controller.ShrinkReserve > 0) {
                this.handSize -= Controller.ShrinkReserve;
                Controller.ShrinkReserve = 0;
            }
            Controller.CurrentlyActive.splice(0, 1);
        }
        if (scored && this.handSize < 12) {
            Controller.ExtraReserve = true;
        } 
        if (Controller.CurrentlyActive.length === 0) {
            Controller.CurrentlyActive = [...Controller.ToBeActive];
            Controller.ToBeActive = [];
        }

        let tiles = this.setEmptyCells();

        if (Controller.CurrentlyActive.length > 0) {
            setTimeout(this.scoreAgain.bind(this), 500);
        } else {
            if (this.checkForWin()) {
                this.setState({Score: this.scoreGame(true), Tiles: tiles, GameOver: true});
                return;
            }
            if (this.checkForLose()) {
                this.setState({Score: this.scoreGame(false), Tiles: tiles, GameOver: true});
                return;
            }
            else {
                this.isScoring = false;
                if (Controller.ExtraReserve) {
                    this.handSize++;
                    this.drawTile();
                    Controller.ExtraReserve = false;
                }
            }
            this.drawTile();
        }
        this.setState({Tiles: tiles});
    }

    setEmptyCells() {
        let tiles = this.state.Tiles;
        for (var i in Controller.AllCells) {
            if (Controller.AllCells[i].CurrentTile === -1) {
                tiles[i] = NullTile;
            }
        }

        return tiles;
    }

    checkForWin() {
        if (Controller.AllCells.filter(t => t.CurrentTile !== -1).length === 0) return true;
        return false;
    }

    checkForLose() {
        if (this.tilesInBag() <= 0 && this.handTiles.length <= 0) return true;
        if (Controller.AllCells.filter(t => t.CurrentTile === -1).length === 0) return true;
        if (this.handSize <= Controller.ShrinkReserve) return true;
        return false;
    }

    scoreGame(wonGame: boolean) {
        if (wonGame) {
            let score = -1 * (this.tilesInBag() + this.handTiles.length + Controller.AllCells.filter(c => c.CurrentTile > -1).length);
            score -= this.handSize;
    
            return score;
        } else {
            let score = 1 * (this.tilesInBag() + this.handTiles.length);
            score += (12 - this.handSize);
    
            return score;
        }
    }

    tilesInBag() {
        return Controller.Tiles.length - this.state.TilesDrawn;
    }

    windowResize() {
        this.setState({WindowWidth: window.innerWidth, WindowHeight: window.innerHeight});
    }
}

export default Game;