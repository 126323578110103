import HandCell from "./HandCell";

const HandArea = (props: any) => {
    return (
        <div>
            <div style={{width:750, margin:'auto', marginTop:10}}>
                <HandCell tile={props.tiles[0]} onClick={props.onClick} selected={props.selected} index={0} />
                <HandCell tile={props.tiles[1]} onClick={props.onClick} selected={props.selected} index={1} />
                <HandCell tile={props.tiles[2]} onClick={props.onClick} selected={props.selected} index={2} />
                <HandCell tile={props.tiles[3]} onClick={props.onClick} selected={props.selected} index={3} />
                <HandCell tile={props.tiles[4]} onClick={props.onClick} selected={props.selected} index={4} />
                <HandCell tile={props.tiles[5]} onClick={props.onClick} selected={props.selected} index={5} />
            </div>
            <div style={{width:750, margin:'auto', marginTop:10}}>
                <HandCell tile={props.tiles[6]} onClick={props.onClick} selected={props.selected} index={6} />
                <HandCell tile={props.tiles[7]} onClick={props.onClick} selected={props.selected} index={7} />
                <HandCell tile={props.tiles[8]} onClick={props.onClick} selected={props.selected} index={8} />
                <HandCell tile={props.tiles[9]} onClick={props.onClick} selected={props.selected} index={9} />
                <HandCell tile={props.tiles[10]} onClick={props.onClick} selected={props.selected} index={10} />
                <HandCell tile={props.tiles[11]} onClick={props.onClick} selected={props.selected} index={11} />
            </div>
        </div>
    );
};

export default HandArea;