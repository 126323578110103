const DamageAlert = (props: any) => {
    let parentClass = 'hidden';
    if (props.gameOver) {
        parentClass = '';
    }

    let dmgVal = props.score;
    let baseSize = props.width;
    if (baseSize > props.height / 2) baseSize = props.height / 2;
    let top = (props.height - baseSize) / 3;
    return (
        <div className={parentClass}>
            <div className="dmg-lg" style={{position: "absolute", textAlign: "center", width: props.width, fontSize: baseSize / 7, top: top, backgroundColor: "white"}}>
                <div style={{verticalAlign: 'middle'}}>
                    Final Score: <br />
                    {dmgVal}
                </div>
            </div>
        </div>
    );
};

export default DamageAlert;