import { Route, Routes } from "react-router-dom";
import Game from "./pages/Game";
import NotMatch from "./pages/NotMatch";
import Survival from "./pages/Survival";

function App() {
  return (
    <>
    <Routes>
        <Route path="/Survive" element={<Survival />} />
        <Route path="/" element={<Game />} />
        <Route path="*" element={<NotMatch />} />
    </Routes>
    </>
  );
}

export default App;
